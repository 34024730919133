const scriptContent = `
  var _jipt = [];
  _jipt.push(["project", "gdm-vendor-portal"]);
  _jipt.push([
    "escape",
    function () {
      window.navigation.reload();
    },
  ]);
  _jipt.push(["preload_texts", false]);
  _jipt.push([
    "edit_strings_context",
    function (context) {
      var separator = "\\n";
      var lineStart = "* ";
      var contextParts = context.split(separator);
      var linkCount = 0;

      for (var i in contextParts) {
        var line = contextParts[i];
        if (lineStart + window.location.href === line) {
          return context;
        }

        if (line.indexOf(lineStart) === 0) {
          linkCount++;
        }

        if (line === "And more...") {
          return context;
        }
      }

      if (linkCount < 5) {
        return context + separator + lineStart + window.location.href;
      }

      return context + separator + "And more...";
    },
  ]);
`;

export const CrowdinScript = () => {
  return (
    <script
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: scriptContent,
      }}
    />
  );
};
