import { CrowdinScript } from "./crowdin";
import { DatadogInit } from "./datadog";
import { PendoScript } from "./pendo";

export const AppScripts = () => (
  <>
    <DatadogInit />
    <CrowdinScript />
    <PendoScript />
  </>
);
